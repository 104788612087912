import { useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";

import { ReactComponent as Bolt } from "../assets/bolt.svg";
import { ReactComponent as Sparkles } from "../assets/sparkles.svg";
import { useTranslation } from "../hooks/useTranslation";
import { OutlinedButtonWithIcon } from "./buttons/OutlinedButtonWithIcon";

const calculateOffer = (number, percent) =>
  percent === 0 ? number : (number - (number * percent) / 100) * 12;
const offerPercent = 20;
const isChecked = (boolean) => (boolean ? "active" : "");

export const Prices = ({ variant, toggleModal }) => {
  const { t } = useTranslation();
  const [offer, setOffer] = useState(0);
  const isMonthly = offer === 0;

  const prices = useMemo(
    () => [
      {
        plan: "Start",
        price: t.free,
        description: t.priceCard1Description,
        optionsTitle: t.priceCard1Title,
        options: [
          t.priceCard1Option1,
          t.priceCard1Option2,
          t.priceCard1Option3,
          t.priceCard1Option4,
        ],
        extraText: t.priceCard1ExtraText,
        to: "/prices",
      },
      {
        plan: "Scale",
        price: 50,
        description: t.priceCard2Description,
        optionsTitle: t.priceCard2Title,
        options: [
          t.priceCard2Option1,
          t.priceCard2Option2,
          t.priceCard2Option3,
        ],
        to: "/prices",
      },
      {
        plan: "Pro",
        price: t.contactWithUs,
        description: t.priceCard3Description,
        optionsTitle: t.priceCard3Title,
        options: [
          t.priceCard3Option1,
          t.priceCard3Option2,
          t.priceCard3Option3,
        ],
        to: "/prices",
      },
    ],
    [t]
  );

  const handleOffer = useCallback(() => {
    setOffer((prevPrice) => (prevPrice === 0 ? offerPercent : 0));
  }, []);

  return (
    <>
      <div className="prices-head">
        {variant === "home" && (
          <h2 className="text-center text-secondary">{t.prices}</h2>
        )}
        {variant === "prices" && (
          <>
            <p className="text-hero weight-700 text-center">{t.prices}</p>
            <p className="prices-description-text">{t.chooseTheTariff}</p>
          </>
        )}
        <div className="prices-switch" data-checked="monthly">
          <p
            className={`body-large switch-text left ${isChecked(isMonthly)}`}
            data-checked="monthly"
          >
            {t.monthly}
          </p>
          <div className="switch" data-checked="monthly" onClick={handleOffer}>
            <div
              className={`thumb prcs icon-20 ${isChecked(!isMonthly)}`}
            ></div>
          </div>
          <div
            className={`flex body-large switch-text right ${isChecked(
              !isMonthly
            )}`}
            data-checked="annually"
          >
            {t.annually}

            <div
              className={`prices-save body-small weight-500 ${isChecked(
                offer !== 0
              )}`}
              data-checked="monthly"
            >
              {t.saveUp20}
            </div>
          </div>
        </div>
      </div>
      <div className="prices-card-list-2">
        {prices.map((item) => (
          <div key={item.plan} className="prices-card">
            <p className="body-medium weight-500 prices-title">{item.plan}</p>
            <h4 className="price">
              {typeof item.price === "number"
                ? `€${calculateOffer(item.price, offer)}`
                : item.price}{" "}
              {typeof item.price === "number" && (
                <span className="body-medium weight-400">
                  / {isMonthly ? t.month : t.year}
                </span>
              )}
            </h4>
            {typeof item.price === "number" && (
              <div className="price-card-icon-container">
                <div className="price-card-icon">
                  <Bolt className="icon-20 text-primary" />
                </div>
              </div>
            )}
            <OutlinedButtonWithIcon
              className="mt-24"
              title={`${t.choose} ${item.plan} ${t.plan}`}
            />
            <p className="prices-description body-medium">{item.description}</p>
            <div className="prices-divider"></div>
            <h4 className="body-medium weight-500">{item.optionsTitle}</h4>
            <ul>
              {item.options.map((option, index) => (
                <li key={option || `price-li-${index}`}>
                  <span className="body-medium">{option}</span>
                </li>
              ))}
            </ul>
            {item?.extraText && (
              <p className="prices-extra body-medium">{item.extraText}</p>
            )}
            {variant === "home" && (
              <Link to={item.to} className="prices-button body-medium">
                {t.learnMore}
              </Link>
            )}
          </div>
        ))}
        <div className="prices-card-list-2-join">
          <div className="prices-sparcles">
            <Sparkles className="icon-20 text-primary" />
          </div>
          <p className="body-medium weight-500 text-secondary">
            {t.marmendFree}
          </p>
          <p className="body-medium mt-8 text-tertiary">{t.support}</p>
          <OutlinedButtonWithIcon
            title={t.tryForFree}
            onClick={toggleModal}
            className="w-fit mt-28"
          />
        </div>
      </div>
      <div className="prices-join-card shadow-7">
        <div className="prices-sparcles">
          <Sparkles className="icon-20 text-primary" />
        </div>
        <div className="text-secondary mr-auto">
          <p className="body-medium weight-500">{t.marmendFree}</p>
          <p className="body-medium mt-8 text-tertiary">{t.support}</p>
        </div>

        <OutlinedButtonWithIcon title={t.tryForFree} onClick={toggleModal} />
      </div>
    </>
  );
};
