import { useState, useEffect } from "react";

import { fallbackLanguage } from "../constants/fallbackLanguage";

const currentLocale = process.env.REACT_APP_PUBLIC_LOCALE || fallbackLanguage;

export const LocalizedImage = ({ src, className }) => {
  const [image, setImage] = useState({ src: null, error: false });

  useEffect(() => {
    (async () => {
      try {
        const imageModule = await import(`../assets/images/${currentLocale}/${src}`);

        setImage({ src: imageModule.default, error: false });
      } catch (error) {
        console.error("Failed to load image:", error);

        const fallbackModule = await import(`../assets/images/en/${src}`);

        setImage({ src: fallbackModule.default, error: true });
      }
    })();
  }, [src, currentLocale]);

  if (image.src === null) {
    return <></>;
  }

  return (
    <img
      src={image.src}
      alt={image?.error ? `fallback ${src}` : src}
      className={className}
      onError={() => setImage((prev) => ({ ...prev, error: true }))}
    />
  );
};
